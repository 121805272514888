import { Injectable } from '@angular/core';
import {  CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/services/auth.service';
//import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerGuard implements CanActivate {

  constructor( private router:Router,private authservice:AuthService){}

  canActivate()
  {
    
    if(this.authservice.loggedIn())
    {
      let users = this.authservice.getLoggedInDetail();
      if(users.user_type == 3 || users.user_type == 4 || users.user_type == 6 || users.user_type == 7)
      {
        return true;
      }
      else
      {
        this.router.navigate(['/landing']);
        return false;
      }
    }
    else
    {
        this.router.navigate(['/landing']);
        return false;
    }


  }
  
}
