import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerComponent } from './customer/customer.component';
import { AdminGuard } from './guards/admin.guard';
import { CustomerGuard } from './guards/customer.guard';
import { LoginGuard } from './guards/login.guard';
import { LandingPageComponent } from './landing-page/landing-page.component';

const routes: Routes = [
  {path:'',component:LandingPageComponent},
  {path:'viewArticle', loadChildren:() => (import("./view-article/view-article.module").then(m => m.ViewArticleModule)) },
  {path:'auth',  canActivate:[LoginGuard], loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
  {path:'' ,component:CustomerComponent, canActivate:[CustomerGuard], loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule)},
  
  {path: 'admin',  canActivate:[AdminGuard], loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  {path:'verify-email/:uuid', loadChildren:() => import("./verify-email/verify-email.module").then(m => m.VerifyEmailModule)},
  {path:'privacy', loadChildren:() => import("./privacy-policy/privacy.policy.module").then(m => m.PrivacyPolicyModule)},
  {path:'terms-and-conditions', loadChildren:() => import("./terms-and-conditions/terms-and-conditions.module").then(m => m.TermsAndConditionsModule) },
  {path:'about', loadChildren:() => import("./about-us/about-us.module").then(m => m.AboutUsModule) },
  {path:'shop', loadChildren:() => import("./shop-home/shop-home.module").then(m => m.ShopHomeModule)},
  {path:'about-viezu-files', loadChildren:() => import("./about-files/about-files.module").then(m => m.AboutFilesModule) },
  {path:'**', redirectTo:'' }
  //{path:'' , canActivate:[LoginGuard], loadChildren: () => import('./frontend/frontend.module').then(m => m.FrontendModule)},

];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
