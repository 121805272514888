import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DebounceDirective } from '../debounce.directive';

let directive = [DebounceDirective];
@NgModule({
  declarations: directive,
  imports: [CommonModule],
  exports: directive,
})
export class DirectiveModuleModule {
  static forRoot(): ModuleWithProviders<DirectiveModuleModule> {
    return {
      ngModule: DirectiveModuleModule,
      providers: directive,
    };
  }
}
