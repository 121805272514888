import { Injectable } from '@angular/core';
import {  CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor( private router:Router,private authservice:AuthService){}

  canActivate()
  {
    
    if(this.authservice.loggedIn())
    {
      let users = this.authservice.getLoggedInDetail();
      if(users.user_type == 5 || users.user_type == 2 )
      {
        return true;
      }
      else
      {
        this.router.navigate(['/landing']);
        return false;
      }
     
    }
    else
    {
        this.router.navigate(['/landing']);
        return false;
    }


  }
  
}
