import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { AuthService } from '../auth/services/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Route, Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private injector: Injector, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authservice = this.injector.get(AuthService)

    if (!authservice.getToken()) { return next.handle(req) }
    else {
      let tokenizeReq = req.clone({
        setHeaders: {
          authorization: `Token ${authservice.getToken()}`
        }
      });
      return next.handle(tokenizeReq).pipe(catchError(x => this.handleAuthError(x)));
    }
  }
  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      localStorage.removeItem('userdetails');
      localStorage.removeItem('token');
      localStorage.removeItem('permissions');
      this.router.navigate(['/auth/login']);
    }
    return throwError(err);
  }
}
