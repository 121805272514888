import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CrudService {
  baseUrl: string = environment.baseUrl;
  constructor(private http: HttpClient, private toastr: ToastrService) { }
  getRequest<T>(requestUrl:string){
    return this.http.get(`${this.baseUrl}/api/v1/${requestUrl}`).toPromise()
    .then((res) => res as T)
    .catch((err) => {this.toastr.error(err.error.detail);})
  }

  get(requestUrl:string):Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/${requestUrl}`)
  }
  
  postRequest<T>(requestUrl:string,data:any){
    return this.http.post(`${this.baseUrl}/api/v1/${requestUrl}`,data).toPromise()
    .then((res) => res as T)
    .catch((err) => {this.toastr.error(err.error.detail);})
  }
  putRequest<T>(requestUrl:string,data:any){
    return this.http.put(`${this.baseUrl}/api/v1/${requestUrl}`,data).toPromise()
    .then((res) => res as T)
    .catch((err) => {this.toastr.error(err.error.detail);})
  }
  // return this._http.delete(`${this.baseUrl}/api/v1/admin/template-category/${id}/`);
  deleteRequest<T>(requestUrl:string){
    return this.http.delete(`${this.baseUrl}/api/v1/${requestUrl}`).toPromise()
    .then((res) => res as T)
    .catch((err) => {this.toastr.error(err.error.detail);})
  }
  csvPostRequest<T>(requestUrl:string,data:any){
    return this.http.post(`${this.baseUrl}/api/v1/${requestUrl}`,data).toPromise()
    .then((res) => res as T)
    .catch((err) => {return err})
  }
}
