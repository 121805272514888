import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/auth/services/auth.service';
import { AnyLengthString } from 'aws-sdk/clients/comprehend';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService implements OnDestroy {

  chatSocket?: WebSocket;
  public socketConnection = new Subject<any>();

  onTicketUpdats: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  onMessageAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  onMessageUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  updateImg: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  tunningPoints: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  cartItem: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  firstMsg: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  chatText: any;
  constructor(private http: HttpClient, private authservice: AuthService) {
    // this.chatSocket = new WebSocket(this.chatSocketUrl);
  }
  updateProfileImg(value: boolean) {
    this.updateImg.next(value);
  }
  updateFirstMessage(value: boolean) {
    this.firstMsg.next(value);
  }
  setFirstMsg(msg: any) {
    this.chatText = msg;
  }
  getFirstMsg() {
    return this.chatText;
  }
  updateTunningPoint(value: boolean) {
    this.tunningPoints.next(value);
  }
  updateCartItem(value: boolean) {
    this.cartItem.next(value);
  }


  initSocket() {
    const uuid: any = this.authservice.getLoggedInDetail().uuid;
    const chatSocketUrl: any = `${environment.socketUrl}/notification/${uuid}`;

    if (this.chatSocket) {
      this.chatSocket.removeEventListener('close', this.onClose);
      this.chatSocket.close();
    }

    this.chatSocket = new WebSocket(chatSocketUrl);

    this.chatSocket.addEventListener('open', this.handleSocketOpen.bind(this));
    this.chatSocket.addEventListener('message', this.handleSocketMessage.bind(this));
    this.chatSocket.addEventListener('close', this.onClose);
  }

  handleSocketOpen() {
    this.socketConnection.next('online');
    console.log('Notification Socket Connected');
  }

  handleSocketMessage(event: any) {
    var data = JSON.parse(JSON.parse(event.data).payload)
    console.log(data, 'in notification service socket response');
    if (data && data.type === 'ticket_info_update') {
      this.onTicketUpdats.next(data);
    }

    if (data.type.includes('admin')) {
      this.onMessageAdmin.next(data);
    }
    else {
      this.onMessageUser.next(data);
    }

  }

  handleSocketClose() {
    this.socketConnection.next('offline');
    setTimeout(() => {
      this.initSocket()
    }, 5000);
  }

  // Can only remove same same function from event listener 
  // which was added so i had to save this
  onClose = this.handleSocketClose.bind(this);

  ngOnDestroy(): void {
    this.chatSocket?.close();
  }

  formatDate(timestamp: any) {
    const dateString = timestamp
    const dateObject = new Date(dateString);
    const gettingDateYear = dateObject.getFullYear();
    const gettingDateMonth = dateObject.getMonth() + 1; // Months are zero-based, so add 1
    const gettingDateDay = dateObject.getDate();
    const gettingDatehours = dateObject.getHours();
    const gettingDateminutes = dateObject.getMinutes();
    const gettingDateseconds = dateObject.getSeconds();
    const gettingDateampm = gettingDatehours >= 12 ? 'PM' : 'AM';
    const gettingDateformattedHours = gettingDatehours % 12 || 12;
    const gettingDateformattedDate = `${gettingDateDay < 10 ? '0' : ''}${gettingDateDay}-${gettingDateMonth < 10 ? '0' : ''}${gettingDateMonth}-${gettingDateYear}`;
    const gettingDateformattedTime = `${gettingDateformattedHours}:${gettingDateminutes < 10 ? '0' : ''}${gettingDateminutes} ${gettingDateampm}`;
    // current Date code start
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
    const day = currentDate.getDate();
    const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
    if (gettingDateformattedDate == formattedDate) {
      return gettingDateformattedTime;
    }
    else if (gettingDateYear != year) {
      const getDate = `${gettingDateDay < 10 ? '0' : ''}${gettingDateDay} ${this.getMonth(gettingDateMonth)} ${gettingDateYear}`;
      return `${getDate} ${gettingDateformattedTime}`;
    }
    else {
      const getDate = `${gettingDateDay < 10 ? '0' : ''}${gettingDateDay} ${this.getMonth(gettingDateMonth)}`;
      return `${getDate} ${gettingDateformattedTime}`;
    }
  }
  getMonth(month: any) {
    const monthNumber = month;
    const dateObject = new Date(2000, monthNumber - 1, 1);
    const monthAbbreviation = dateObject.toLocaleString('en-US', { month: 'short' });
    return monthAbbreviation
  }

}
