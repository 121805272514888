import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl = environment.baseUrl;
  constructor(private _http:HttpClient) 
  {


  }


  login(data:any)
  {
    return this._http.post<any>(this.baseUrl+'/api/v1/account/login/',data);
  }

  loggedIn()
  {
    return !!localStorage.getItem('token');
  }

  getToken()
  {
    return localStorage.getItem('token');
  }


  getLoggedInDetail()
  {
    return JSON.parse(localStorage.getItem('userdetails') || '');
  }

  forgotPassword(data:any)
  {
    return this._http.post(this.baseUrl+'/api/v1/account/forgot-password',data);
  }

  otpVerify(data:any)
  {
    return this._http.post(this.baseUrl+'/api/v1/account/otp-verify',data);
  }

  resetPassword(data:any)
  {
    return this._http.post(this.baseUrl+'/api/v1/account/reset-password',data);
  }
  getEmailSetting(params:any) {
    return this._http.get(`${this.baseUrl}/api/v1/admin/email-log-settings/`, { params });
  }
  createEmailSetting(data:any) {
    return this._http.post(`${this.baseUrl}/api/v1/admin/email-log-settings/`,data);
  }
  editEmailSetting(id:any,data:any) {
    return this._http.put(`${this.baseUrl}/api/v1/admin/email-log-settings/${id}/`,data);
  }
  deleteEmailSetting(id:any) {
    return this._http.delete(`${this.baseUrl}/api/v1/admin/email-log-settings/${id}/`);
  }
  getProductList(page: number) {
    return this._http.get(`${this.baseUrl}/api/v1/shop/products/list?page=${page}`);
  }
  getTagsList(page: number) {
    return this._http.get(`${this.baseUrl}/api/v1/shop/tags?page=${page}`);
  }
  addTags(data: any) {
    return this._http.post(`${this.baseUrl}/api/v1/shop/tags`,data);
  }
  deleteTag(id: number) {
    return this._http.delete(`${this.baseUrl}/api/v1/shop/tags?tag_id=${id}`);
  }
  getCategoryList(page: number) {
    return this._http.get(`${this.baseUrl}/api/v1/shop/category?page=${page}`);
  }
  getSpecificCategory(id: number) {
    return this._http.get(`${this.baseUrl}/api/v1/shop/category?category_id=${id}`);
  }
  addCategory(data: any) {
    return this._http.post(`${this.baseUrl}/api/v1/shop/category`,data);
  }
  deleteCategory(id: number) {
    return this._http.delete(`${this.baseUrl}/api/v1/shop/category?category_id=${id}`);
  }
  getShippingZone() {
    return this._http.get(`${this.baseUrl}/api/v1/shop/shipping/zones/list`);
  }
}
