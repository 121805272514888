import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private router: Router, private authservice: AuthService) { }

  canActivate() {
    if (this.authservice.loggedIn() == false) {
      localStorage.removeItem('userdetails');
      localStorage.removeItem('token');
      localStorage.removeItem('permissions');
      return true;
    }
    else {
      let users = this.authservice.getLoggedInDetail();
      if (users.user_type == 3 || users.user_type == 4|| users.user_type == 6|| users.user_type == 7) 
      {
        this.router.navigate(['/dashboard']);
      }
      if (users.user_type == 5 || users.user_type == 2) 
      {
        this.router.navigate(['admin']);
      }

      return false;
    }




  }

}
