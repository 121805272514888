// src/environments/environment.staging.ts
export const environment = {
  production: false,
  baseUrl: 'https://try.readme.io/https://testing.api.viezu-client-files.com',
  chatNavigationUrl: 'https://testing.viezu-client-files.com/',
  encryptionIV: 'e7KwBMezIRVtYvQB',
  encryptionKey: 'r6piftQv2vCP5I3v',
  socketUrl: 'wss://testing.api.viezu-client-files.com',
  // captchaSecrateKey: '6Ld1lTQqAAAAAD2Xev8m6RC_Dr_vqIwXityjDYxZ',
  captchaSecrateKey: '6LfzqjQqAAAAAHPQDYhTXmHJGik-vC0jb7auSLr6',
  
  AWS_ACCESS_KEY_ID: 'AKIAZ32YI3P6FFI65MXD',
  AWS_SECRET_ACCESS_KEY: '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',
  AWS_S3_REGION_NAME: 'us-east-1',
  AWS_STORAGE_BUCKET_NAME: 'filemaker-stage',
  STRIPE_PUBLIC_KEY: "pk_test_51IWT82K0GVil4wLFWAWM0fnIBNRgOOknnQefZe1t8XqErq63kqsXwWgxSTLzrKwygKzqK7QIlZXIlcYLGUsTiArH00stJml69P",
  default_debounce_time: {
    search: 300, // existing property
    click: 200   // new property added
  }
};
