import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subscription, fromEvent, Observable, debounceTime } from 'rxjs';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[appDebounce]',
})
export class DebounceDirective {
  @Input('appDebounce_click_time') click_debounce_time: number;
  @Input('appDebounce_search_time') search_debounce_time: number;

  @Output('appDebounce_click') click: EventEmitter<void> =
    new EventEmitter<void>();
  @Output('appDebounce_keypress') keypress: EventEmitter<KeyboardEvent> =
    new EventEmitter<KeyboardEvent>();
  @Output('appDebounce_keydown') keydown: EventEmitter<KeyboardEvent> =
    new EventEmitter<KeyboardEvent>();

  private click$!: Subscription;
  private keypress$!: Subscription;
  private keydown$!: Subscription;

  constructor(private _ele: ElementRef) {
    this.click_debounce_time = environment.default_debounce_time.click;
    this.search_debounce_time = environment.default_debounce_time.search;
  }

  ngAfterViewInit(): void {
    console.log('called');
    this.click$ = this.delay_event(
      fromEvent(this._ele.nativeElement, 'click'),
      this.get_click_debounce_time()
    ).subscribe(() => this.click.emit());
    this.keypress$ = this.delay_event(
      fromEvent(this._ele.nativeElement, 'keypress'),
      this.get_search_debounce_time()
    ).subscribe((event) => this.keypress.emit(event));
    this.keydown$ = this.delay_event(
      fromEvent(this._ele.nativeElement, 'keydown'),
      this.get_search_debounce_time()
    ).subscribe((event) => {
      this.keydown.emit(event);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!Number.isFinite(this.click_debounce_time)) {
      this.click_debounce_time = this.get_click_debounce_time();
    }

    if (!Number.isFinite(this.search_debounce_time)) {
      this.search_debounce_time = this.get_search_debounce_time();
    }
  }

  private get_click_debounce_time() {
    return this.click_debounce_time || environment.default_debounce_time.click;
  }

  private get_search_debounce_time() {
    return (
      this.search_debounce_time || environment.default_debounce_time.search
    );
  }

  delay_event(event: Observable<any>, debounce_time: number): Observable<any> {
    return event.pipe(debounceTime(debounce_time));
  }

  ngOnDestroy(): void {
    this.click$.unsubscribe();
    this.keydown$.unsubscribe();
    this.keypress$.unsubscribe();
  }
}
