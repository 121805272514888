import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { apiResponse } from '../models/apiResponse';
import { URLS } from '../global-files/global.const';
import { ApiService } from '../auth/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerBusinessService {
  baseUrl = environment.baseUrl;
  private notificationCount = new BehaviorSubject<number>(0);
  notificationCount$ = this.notificationCount.asObservable();
  constructor(
    private _http: HttpClient,
    private apiService: ApiService
  ) { }

  broadCastNotification(data: any) {
    this.notificationCount.next(data)
  }

  getCounts() {
    const url = URLS.getCountUrlBusiness;
    return this.apiService.getRequest(url);
  }
  // getFileSubmittedData() {
  //   const url = URLS.getFileSubmittedBusiness;
  //   return this.apiService.getRequest(url);

  // getMyToolUsage() {
  //   const url = URLS.getMyToolUsageBusiness;
  //   return this.apiService.getRequest(url);
  // }

  // getSupportsTicketStatus() {
  //   const url = URLS.getSupportTicketBusiness;
  //   return this.apiService.getRequest(url);
  // }
  // getSupportsTicketStatusByDateFilter(from:any,to:any) {
  //   const url = `${URLS.getSupportTicketBusiness}?from_date=${from}&to_date=${to}`;
  //   return this.apiService.getRequest(url);
  // }
  getBusinessDynamicDrilldown() {
    const url = URLS.getDynamicDrilldownBusiness;
    return this.apiService.getRequest(url);
  }

}
