<script>

</script>
<div class="landing-page">
    <div class="landing-page-inner">
        <div class="landing-left">
            <a class="login">
                <div style="background-color: rgb(88,194,242);">
                    <!-- <img src="../../assets/images/vehiclediv.png" alt="Login" /> -->
                    <img class="logo logodimg" src="../../assets/images/logo.svg" alt="AI Remap" />
                </div>
            </a>
            <a href="/about-viezu-files" >
                <div style="background-color: #000000;">
                    <!-- <img src="assets/images/aboutfile.png" alt="files" /> -->
                    <img class="rightuparrow" src="assets/images/icon/arrow-up-right.png" alt="right arrow" />
                    <div class="content">
                        <p>ABOUT</p>
                        <p>VIEZU</p>
                        <p>FILES</p>
                    </div>
                </div>
            </a>
        </div>
        <div class="landing-right">
            <div class="top-right">
                <a (click)="goToShop()">
                    <div style="background-color: #081B46;">
                        <img class="rightuparrow blackarrow" src="assets/images/icon/arrow-up-right.png" alt="right arrow" />
                        <div class="content">
                            <p class="center shop">SHOP</p>
                        </div>
                    </div>
                </a>
                <a routerLink="/auth/login" >
                    <div class="login-img">
                        <img src="../../assets/images/shopDiv!.webp" alt="login" />
                    </div>
                    <div class="login-text" style="background-color: #081B46;">
                        <!-- <img src="assets/images/shopdiv.png" alt="login" /> -->
                        <img class="rightuparrow blackarrow" src="assets/images/icon/arrow-up-right.png" alt="right arrow" />
                        <div class="content loginpadding">
                            <p class="shop">LOGIN/</p>
                            <p class="shop">REGISTER</p>
                        </div>
                    </div>
                </a>
            </div>

            <div class="bottom-right">
                <a href="https://www.remap101.co.uk/" target="_blank">
                    <div>
                        <img class="rightuparrow b-10" src="assets/images/icon/arrow-up-right.png" alt="right arrow" />
                        <div class="content-top">
                            <p class="center">TRAINING</p>
                        </div>
                        <div>
                            <img src="../../assets/images/training.webp" alt="training" />
                        </div>
                        <div class="content-bottom">
                            <p>TRAINING</p>
                        </div>
                    </div>
                </a>
                <a routerLink="/about" >
                    <div style="background-color:#081B46">
                        <!-- <img src="assets/images/shopdiv.png" alt="services" /> -->
                        <img class="rightuparrow" src="assets/images/icon/arrow-up-right.png" alt="right arrow" />
                        <div class="content">
                            <p>ABOUT</p>
                            <p>VIEZU</p>
                        </div>
                    </div>
                </a>
                <a href="https://viezu.com/dealers/become-a-dealer/" target="_blank">
                    <div style="background-color: #2091FF;">
                        <!-- <img src="assets/images/contactdiv.png" alt="contact" /> -->
                        <img class="rightuparrow blackarrow" src="assets/images/icon/arrow-up-right.png" alt="right arrow" />

                        <div class="content blackclr">
                            <p>CONTACT</p>
                            <p>US TO</p>
                            <p>BECOME A</p>
                            <p>VIEZU</p>
                            <p>DEALER</p>
                        </div>
                    </div>
                </a>
            </div>

       </div>
        </div>
        </div>
