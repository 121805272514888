export const cusRequestUrl = {
    getBrandModal: 'file/service/vehicle/model/list/',
    getVehicleControlList: 'file/service/vehicle/control/list/',
    getProductFilter: 'shop/products/filter?category_id=',
    getProductDetail: 'shop/products/',
    customerCart: 'customer/cart/',
    getArticleList: 'zoho/list/articles',
    shopOrderCreate: 'shop/orders/create',
    getPurchaseHistory: 'customer/purchase-history/',
    updateShopOrderStatus: `shop/orders/status`,
    createRequestFile: `file/service/request/create`,
    getVehicleTypeList: `file/service/vehicle/type/list`,
    getVehicleBrandList: `file/service/vehicle/brand/list/`,
    updateCustomerBusinessProfile: `customer/business-profile/`,
    updateCustomerIndividualProfile: `customer/individual-profile/`,
    getCustomerProfile: `account/profile/`,
    getEVCdetail:`customer/customer/evc/purchase_history`,
    getTicketCategory: `admin/ticket-category`,
    createTicket: `customer/ticket/`,
    getZohoRootCategories: `zoho/get/root/categories`,
    getInvoiceHistory: `customer/customer/zoho/invoice_history`,
    downloadInvoice: `customer/customer/zoho/invoice_download/`,
    getEVCcustomer: `account/evc/customer`,
    customerSpentHistory: `customer/spent-history?uuid=`,
    updateDownloadCount: `file/service/request/files/download/counter/`,
    dealerCreditUpdate:`/customer/reseller/dealers/credit_update`,
    getDealerOfReseller: `customer/reseller/dealers/`,
    addNewVehicle: `admin/vehicle/create/new`,
    getVehicleList: `admin/vehicle/list`,
    getProduct: `admin/product`,
    getEcuModelAndVersion: `file/service/vehicle/model/get_all_brands/`
}